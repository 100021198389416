require("./bootstrap");

import Vue, { createApp } from "vue";
import router from "./router";

import { useClient, defaultPlugins } from "villus";
import vueRecaptcha from "vue3-recaptcha2";

import "animate.css";
import Component01 from "./vue/components/Component-01.vue";
import Component02 from "./vue/components/Component-02.vue";
import Component03 from "./vue/components/Component-03.vue";
import Component04 from "./vue/components/Component-04.vue";
import Component05 from "./vue/components/Component-05.vue";
import Base from "./vue/pages/Base.vue";
import AboutUs from "./vue/pages/AboutUs.vue";
import Clients from "./vue/pages/Clients.vue";
import Contact from "./vue/pages/Contact.vue";
import Home from "./vue/pages/Home.vue";
import Index from "./vue/pages/Index.vue";
import Services from "./vue/pages/Services.vue";
import Vehicles from "./vue/pages/Vehicles.vue";
import HeaderSlider from "./vue/components/HeaderSlider.vue";
import Navbar from "./vue/components/Navbar.vue";
import PageFooter from "./vue/components/PageFooter.vue";
import Quotation from "./vue/components/Quotation.vue";
import VehiclesCC from "./vue/components/Vehicles.vue";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import "vuetify/styles";

import { VDateInput } from "vuetify/labs/VDateInput";

const app = createApp({
  components: {
    Component01,
    Component02,
    Component03,
    Component04,
    Component05,
    HeaderSlider,
    Navbar,
    PageFooter,
    Quotation,
    VehiclesCC,
    Base,
    AboutUs,
    Clients,
    Contact,
    Home,
    Index,
    Services,
    Vehicles,
  },
});

const vuetify = createVuetify({
  components: {
    ...components,
    VDateInput,
  },
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});

const authPlugin = ({ opContext }) => {
  opContext.headers.Authorization =
    "Bearer 0581fc22f39986481666885ed7760d9ae737541673068a7e266920366f3caa39b650493b307388701875ea28354bc74fe9a801431a771877b112247f00c9deb292cc0eb3396180a9818717d9dde47dd01fdddeb8c7d54a11ebcf222478645cb8c7825a521663ac98dc64bd8319ff9c0fdc6268cc64fac7cf24bb84ba1d558b96";
};

const villus = useClient({
  url: "https://noseassapolio.online/graphql",
  use: [authPlugin, ...defaultPlugins()],
});

app.use(vueRecaptcha).use(router).use(vuetify).use(villus).mount("#app");
