<template>
  <section class="trf-footer">
    <div class="container pt-5 pb-3">
      <div class="row">
        <div class="col-12 col-md-4 trf-footer__section1">
          <img :src="props.data?.logo" alt="" />
          <p>
            {{ props.data?.description }}
          </p>
        </div>
        <div class="col-12 col-md-4 trf-footer__section2">
          <h3>Contáctenos</h3>
          <ul class="list-unstyled pt-3">
            <li class="mb-3">
              <img src="../../../images/icons/phone-footer.png" alt="" />
              <span>{{ props.data?.cellphone }}</span>
            </li>

            <li class="mb-3">
              <img src="../../../images/icons/mail-footer.png" alt="" />
              <span>{{ props.data?.email }}</span>
            </li>

            <li class="d-block d-md-none mb-3">
              <router-link
                class="text-white px-2 fs-6 text-decoration-underline"
                :to="'libro-de-reclamaciones'"
              >
                Libro de reclamaciones
              </router-link>
            </li>
          </ul>
        </div>
        <div
          class="col-12 col-md-4 trf-footer__section3 d-flex flex-column align-items-end"
        >
          <ul class="list-unstyled">
            <li class="px-2">
              <a v-if="props.data?.facebook" :href="props.data?.facebook">
                <img
                  class="m-0"
                  src="../../../images/icons/facebook-footer.png"
                  alt=""
                />
              </a>
            </li>
            <li class="px-2">
              <a v-if="props.data?.instagram" :href="props.data?.instagram">
                <img
                  class="m-0"
                  src="../../../images/icons/instagram-footer.png"
                  alt=""
                />
              </a>
            </li>
            <li class="px-2">
              <a v-if="props.data?.twitter" :href="props.data?.twitter">
                <img
                  class="m-0"
                  src="../../../images/icons/twitter-footer.png"
                  alt=""
                />
              </a>
            </li>
          </ul>
          <div class="map px-2">
            <iframe
              :src="props.data?.maps"
              width="100%"
              height="100%"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div class="col-12 footer-actions pt-4">
          <div class="row">
            <div class="col-12 col-md-4">
              <h3 class="d-grid">
                © transporterafael.com 2024.
                <span>Todos los Derechos Reservados.</span>
              </h3>
            </div>
            <div class="col-12 col-md-8 d-none d-md-block text-end">
              <ul class="d-inline-flex list-unstyled gap-4">
                <template v-for="nav in props.data?.menu">
                  <li class="px-1" v-if="nav.Active">
                    <router-link class="text-decoration-none" :to="nav.Link">
                      {{ nav.Name }}
                    </router-link>
                  </li>
                </template>

                <li class="px-1">
                  <router-link
                    class="text-decoration-none"
                    :to="'libro-de-reclamaciones'"
                  >
                    Libro de reclamaciones
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import defineProps from "vue";

const props = defineProps({
  data: Object,
});
</script>

<style lang="scss">
.trf-footer {
  width: 100%;
  min-height: 300px;
  background-color: #005f96;
  padding-top: 2rem;
  padding-bottom: 1rem;

  &__section1 {
    padding-right: 4rem;
    img {
      width: 165px;
      @media (min-width: 360px) and (max-width: 800px) {
        width: 125px;
      }
    }

    p {
      color: #ffffff;
      font-size: 16px;
      line-height: 1.2;
      font-weight: 400;
      padding: 1.4rem 0;
      @media (min-width: 360px) and (max-width: 800px) {
        font-size: 14px;
      }
    }
  }

  &__section2 {
    h3 {
      color: #ffff;
      font-size: 28px;
    }

    ul {
      li {
        img {
          width: 40px;
          margin-right: 0.7rem;
        }
        span {
          color: #ffff;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }

  &__section3 {
    ul {
      display: inline-flex;
      width: 100%;
      justify-content: end;
      li {
        a {
          img {
            width: 40px;
            margin-right: 0.7rem;
          }
        }
      }
    }

    .map {
      width: 350px;
      height: 180px;
      // background-image: url(../../../images/map.png);
    }
  }
}

.footer-actions {
  h3 {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 1.5;
    span {
    }
  }

  li {
    a {
      color: #fff;
      font-size: 14px;
      text-decoration: none;
      font-weight: 600;
    }
    &:hover {
      border-bottom: 3px solid #fff;
    }
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .trf-footer {
    position: relative;
    &__section1 {
      img {
      }

      p {
      }
    }

    &__section2 {
      h3 {
      }

      ul {
        li {
          img {
            width: 30px;
            margin-right: 0.7rem;
          }
          span {
          }
        }
      }
    }

    &__section3 {
      ul {
        position: absolute;
        top: 22px;
        li {
          a {
            img {
            }
          }
        }
      }

      .map {
        width: 100%;
        height: 200px;
        background-size: cover;
      }
    }
  }

  .footer-actions {
    h3 {
      span {
      }
    }

    li {
      a {
      }
    }
  }
}
</style>
