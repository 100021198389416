<template>
  <div :class="props.template" class="position-absolute top-0 w-100 start-0">
    <section
      class="trf-navbar container d-flex justify-content-between align-items-center"
    >
      <router-link to="/inicio">
        <img
          class="trf-navbar__logo"
          @click=""
          :src="props.data?.logo"
          alt=""
        />
      </router-link>

      <img
        class="trf-navbar__icon"
        src="../../../images/icons/menu-yellow.svg"
        alt=""
        @click="showNavbar = true"
      />

      <nav class="trf-navbar__menu trf-navbar__menu-desktop">
        <img
          class="close"
          src="../../../images/icons/close-yellow.svg"
          alt=""
          @click="showNavbar = false"
        />
        <ul class="list-unstyled list-inline m-0">
          <template v-for="nav in props.data?.menu">
            <li class="list-inline-item px-3" v-if="nav.Active">
              <router-link
                class="text-decoration-none"
                :to="nav.Link"
                @click="showNavbar = false"
              >
                {{ nav.Name }}
              </router-link>
            </li>
          </template>
        </ul>
      </nav>

      <nav class="trf-navbar__menu" v-if="showNavbar">
        <img
          class="close"
          src="../../../images/icons/close-yellow.svg"
          alt=""
          @click="showNavbar = false"
        />
        <ul class="list-unstyled list-inline m-0">
          <template
            v-for="(nav, index) in props.data?.menu"
            v-bind:style="'animation-delay: 0.' + index + '000s;'"
          >
            <li
              class="list-inline-item px-3 animate__animated animate__fast animate__fadeInDown"
              v-if="nav.Active"
              v-bind:style="'animation-delay: 0.' + index + '000s;'"
            >
              <router-link
                class="text-decoration-none"
                :to="nav.Link"
                @click="showNavbar = false"
              >
                {{ nav.Name }}
              </router-link>
            </li>
          </template>
        </ul>
      </nav>
    </section>
  </div>
</template>

<script setup>
import defineProps from "vue";
import { onMounted, computed, ref } from "vue";

const showNavbar = ref(false);
const props = defineProps({
  template: String,
  data: Object,
});
</script>

<style lang="scss">
.trf-navbar {
  width: 100vw;
  height: 100px;
  position: relative;
  z-index: 9999;
  &__logo {
    width: 160px;
  }
  .close {
    display: none;
  }
  &__icon {
    display: none;
    width: 35px;
    margin: 0 10px;
  }
  &__menu {
    &-desktop {
      display: block;
    }
    ul {
      li {
        a {
          font-size: 20px;
          font-weight: 500;
          color: #fff;
          &:hover {
            color: #ed9325;
          }
        }
        &:last-child {
          padding-right: 0;
        }
        .router-link-active {
          font-weight: 600;
          color: #ed9325 !important;
          border-bottom: 2px solid;
        }
      }
    }
  }
}

.Template-1 {
  margin-bottom: 5rem;
  .trf-navbar {
    &__menu {
      ul {
        li {
          a {
            color: #005f96;
          }
        }
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .trf-navbar {
    &__logo {
      width: 125px;
      margin: 0 10px;
    }
    .close {
      display: block;
      border-radius: 50px;
      width: 40px;
      position: absolute;
      top: 3rem;
      right: 3rem;
    }
    &__icon {
      display: block;
    }
    &__menu {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background: rgba(0, 14, 22, 0.9607843137);
      z-index: 999;
      &-desktop {
        display: none;
      }
      ul {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        li {
          a {
            font-size: 34px;
            font-weight: 500;
            color: #fff;
            &:hover {
            }
          }
          &:last-child {
            padding-right: auto !important;
          }
          .router-link-active {
          }
        }
      }
    }
  }
}
</style>
